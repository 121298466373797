import AdvText from "@components/data/text";
import ContractsHost from "@components/dynamic/contracts/contracts-host";
import DataProviderHost from "@components/dynamic/data-provider-host";
import AdvDynamicComponent from "@components/dynamic/dynamic-component";
import { TDynamicPage, TDynamicPagePayload } from "@components/dynamic/dynamic-page/types";
import PageParameterHost from "@components/dynamic/parameter-mapping";
import AdvPage from "@components/layout/page";
import AdvLoading from "@components/other/loading";
import { TPageComponentProps } from "@components/page-component";
import { replacePagePrefix } from "@data/designer/file";
import { EContractEvent, recoilContractEvent } from "@data/dynamic-page";
import { buildPageIDForVariableID } from "@data/parameters";
import { EPageLoaderMode, useAdvPageLoader } from "@hooks/dynamic/useAdvPageLoader";
import { usePageTitle } from "@hooks/dynamic/usePageTitle";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import {
    TAdvTransactionInterface,
    useAdvRecoilTransaction,
} from "@hooks/recoil-overload/useAdvRecoilTransaction";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import PageNotFound from "@pages/404";
import { trans_assert } from "@utils/assert-trans";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { buildUniquePageID, gAdvDynPageName, parsePageName } from "@utils/page-parser";
import { nanoid } from "nanoid";
import { NextPage } from "next";
import { useRouter } from "next/router";
import React, { useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { atom, atomFamily } from "recoil";

export const dynamicPageAtomName = atom<string>({
    key: "dynamicPageName_PageAtom",
    default: "",
});

const dynamicPageAtom = atomFamily<
    { page: TDynamicPage | undefined; pagename: string; pagecompid: string },
    string
>({
    key: "dynamicPage_PageAtom",
    default: { page: undefined, pagename: "", pagecompid: "" },
});

const dynamicPageUniquenessAtom = atomFamily<string, string>({
    key: "dynamicPageUniqueness_PageAtom",
    default: "",
});

export const gResetDynamicPageTrans = (tb: TAdvTransactionInterface) => (pageID: string) => {
    tb.reset(dynamicPageAtom(pageID));
    tb.reset(dynamicPageUniquenessAtom(pageID));
};

export const useDynamicPage = () => {
    const dynamicPageName = useAdvRecoilValue(dynamicPageAtomName);
    const dynamicPage = useAdvRecoilValue(dynamicPageAtom(dynamicPageName));
    const { initPageTrans, pageName, loadablePage } = useAdvPageLoader(gAdvDynPageName);

    const { pathname, query, isReady } = useRouter();

    /** Wenn verfügbar den Title der Page (anstatt PageName) nutzen */
    const { title, sectitle } = usePageTitle(pageName ?? "");

    const initPageWrapper = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (
                pathname: string,
                query: Record<string, string | string[] | undefined>,
                pageName: string,
                dynamicPagePayload: TDynamicPagePayload,
                mode: EPageLoaderMode,
            ) => {
                const pageID = buildUniquePageID(pathname, query);
                const uniqueness = tb.get(dynamicPageUniquenessAtom(pageID));
                const pageUniqueness = JSON.stringify(dynamicPagePayload);
                if (uniqueness != pageUniqueness) {
                    initPageTrans(tb)(pageName, dynamicPagePayload, mode, (tb) => (page) => {
                        tb.set(dynamicPageAtomName, pageID);
                        tb.set(dynamicPageAtom(pageID), {
                            page: page,
                            pagename: pageName ?? "",
                            pagecompid: nanoid(),
                        });
                        tb.set(dynamicPageUniquenessAtom(pageID), pageUniqueness);
                    });
                } else {
                    tb.set(dynamicPageAtomName, pageID);
                }
                // Vorherige ContractEvents zurücksetzen. ContractStartEvent ist zu spät, da die ContractActions-Komponenten
                // zu dem Zeitpunkt schon lange gerendert wurden und somit die hinterlegten Actions ggf. schon ausgeführt
                // wurden. Die States sollten zurückgesetzt werden, bevor die Page an sich rendert:
                tb.reset(
                    recoilContractEvent({
                        page: buildPageIDForVariableID(pathname, query),
                        event: EContractEvent.Error,
                    }),
                );
                tb.reset(
                    recoilContractEvent({
                        page: buildPageIDForVariableID(pathname, query),
                        event: EContractEvent.Fulfilled,
                    }),
                );
                tb.reset(
                    recoilContractEvent({
                        page: buildPageIDForVariableID(pathname, query),
                        event: EContractEvent.Cancel,
                    }),
                );
            },
        [initPageTrans],
    );
    const initPage = useAdvRecoilTransaction(initPageWrapper, [initPageWrapper]);

    const checkPageIDTrans = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (pathname: string, query: Record<string, string | string[] | undefined>) => {
                const pageID = buildUniquePageID(pathname, query);
                const oldPageID = tb.get(dynamicPageAtomName);
                if (JSON.stringify(pageID) != JSON.stringify(oldPageID))
                    tb.set(dynamicPageAtomName, pageID);
            },
        [],
    );
    const checkPageID = useAdvRecoilTransaction(checkPageIDTrans, [checkPageIDTrans]);

    useAdvEffect(() => {
        if (isReady) {
            if (
                loadablePage.IsLoaded() &&
                parsePageName(pathname, query) == loadablePage.Get().Name
            ) {
                initPage(
                    pathname,
                    query,
                    loadablePage.Get().Name,
                    loadablePage.Get(),
                    EPageLoaderMode.Normal,
                );
            } else {
                checkPageID(pathname, query);
            }
        }
    }, [loadablePage, initPage, query, isReady, pathname, checkPageID]);

    return {
        dynamicPage,
        pageName,
        pageTitle: title ?? "Dynamische Seite (via Page-Loader)",
        secondaryPageTitle: sectitle,
        hasErr: loadablePage.HasError(),
    };
};

export const PageDynamicImpl = ({
    dynamicPage,
    pageName,
    hasErr,
    pageProps,
}: {
    dynamicPage: {
        page: TDynamicPage | undefined;
        pagename: string;
        pagecompid: string;
    };
    pageName: string | undefined;
    hasErr: boolean;
    pageProps: TPageComponentProps;
}) => {
    /** Die Basis-Komponente (AdvDynamicPage) der angezeigten Seite. */
    const dynamicPageComponent = useMemo<React.JSX.Element>(() => {
        if (!hasErr) {
            const dynPage = dynamicPage.page;
            if (dynPage !== undefined && dynamicPage.pagename == pageName) {
                const baseComponent = dynPage.components.find(
                    (comp) => comp.key == dynPage.baseComponentKey,
                );
                trans_assert(
                    baseComponent != undefined,
                    `BaseComponent undefined: ${JSON.stringify(dynamicPage)}`,
                );
                return (
                    <AdvDynamicComponent
                        key={"dc_" + dynamicPage.pagecompid}
                        component={baseComponent}
                        components={dynPage.components}
                        pageProps={pageProps}
                    />
                );
            } else
                return (
                    <AdvLoading
                        isLoading={true}
                        spinnerProps={{
                            label: `Lade ${replacePagePrefix(pageName ?? "") ?? "<Page>"}...`,
                        }}
                    />
                );
        } else {
            return (
                <PageNotFound>
                    <AdvText>Unbekannte Seite</AdvText>
                </PageNotFound>
            );
        }
    }, [dynamicPage, hasErr, pageName, pageProps]);

    return (
        <>
            <DataProviderHost />
            <PageParameterHost />
            <ContractsHost />
            {dynamicPageComponent}
        </>
    );
};

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
const PageDynamicComp: NextPage<TPageComponentProps> = (pageProps: TPageComponentProps) => {
    const { dynamicPage, pageName, pageTitle, secondaryPageTitle, hasErr } = useDynamicPage();

    const [pageKey, setPageKey] = useState(getUniqueId());
    useHotkeys(
        "Shift+F5",
        () => {
            // "Soft reload", nur den Content der Page
            setPageKey(getUniqueId());
        },
        { preventDefault: true },
    );

    return (
        <AdvPage
            title={pageTitle != "" ? pageTitle : replacePagePrefix(pageName ?? "")}
            secondaryTitle={secondaryPageTitle}
        >
            <PageDynamicImpl
                key={`pagedynamic_${pageKey}`}
                dynamicPage={dynamicPage}
                pageName={pageName}
                hasErr={hasErr}
                pageProps={pageProps}
            ></PageDynamicImpl>
        </AdvPage>
    );
};

const PageDynamic = React.memo(PageDynamicComp, deepCompareJSXProps);
export default PageDynamic;
