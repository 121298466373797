import deepCopy from "@utils/deep-copy";

export type TAdvContractInterfaceImplEntryRelation = {
    SQLProviderID: string; // provider key in web
    ProviderFieldName: string;
};

export type TAdvContractInterfaceImplValueRangeEntryRelation = {
    SQLProviderID: string; // provider key in web
    ProviderFieldName: string;
    ProviderCompareFieldName: string;
};

export type TAdvContractInterfaceImplEntry = {
    EntryName: string;
    ProviderValue: TAdvContractInterfaceImplEntryRelation;
    ValueRange: TAdvContractInterfaceImplValueRangeEntryRelation;
};

export type TAdvContractInterfaceImplementation = {
    Entries: Array<TAdvContractInterfaceImplEntry>;
};

export type TAdvContract = {
    Name: string;
    ContractName: string;
    ContractInterfaceImpl: TAdvContractInterfaceImplementation;
};

export const CheckContractCorrectness = (aContract: TAdvContract): TAdvContract => {
    const res = deepCopy(aContract);
    const resKeys = Object.keys(res);
    if (!resKeys.includes("Name")) res["Name"] = "";
    if (!resKeys.includes("ContractName")) res["ContractName"] = "";
    if (!resKeys.includes("ContractInterfaceImpl")) {
        res["ContractInterfaceImpl"] = {
            Entries: [],
        };
    } else {
        const interfaceKeys = Object.keys(res.ContractInterfaceImpl);
        if (!interfaceKeys.includes("Entries")) {
            res.ContractInterfaceImpl["Entries"] = [];
        } else {
            res.ContractInterfaceImpl.Entries.forEach((val) => {
                const entryKeys = Object.keys(val);
                if (!entryKeys.includes("EntryName")) {
                    val["EntryName"] = "";
                }
                if (!entryKeys.includes("ProviderValue")) {
                    val["ProviderValue"] = { ProviderFieldName: "", SQLProviderID: "" };
                } else {
                    const defKeys = Object.keys(val.ProviderValue);
                    if (!defKeys.includes("ProviderFieldName")) {
                        val.ProviderValue["ProviderFieldName"] = "";
                    }
                    if (!defKeys.includes("SQLProviderID")) {
                        val.ProviderValue["SQLProviderID"] = "";
                    }
                }
                if (!entryKeys.includes("ValueRange")) {
                    val["ValueRange"] = {
                        ProviderFieldName: "",
                        SQLProviderID: "",
                        ProviderCompareFieldName: "",
                    };
                } else {
                    const defKeys = Object.keys(val.ValueRange);
                    if (!defKeys.includes("ProviderFieldName")) {
                        val.ValueRange["ProviderFieldName"] = "";
                    }
                    if (!defKeys.includes("SQLProviderID")) {
                        val.ValueRange["SQLProviderID"] = "";
                    }
                    if (!defKeys.includes("ProviderCompareFieldName")) {
                        val.ValueRange["ProviderCompareFieldName"] = "";
                    }
                }
            });
        }
    }
    return res;
};

export type TAdvContractPayload = TAdvContract;

export enum EAdvContractEventType {
    Start = 0,
    InputData = 1,
    Question = 2,
    Fulfilled = 3,
    Cancelled = 4,
    Progress = 5,
    SetEditData = 6,
    InitialDataChanged = 7,
    Retry = 8,
    FieldData = 9,
    BinaryFile = 10,
    InputFieldNumber = 11,
    InputDropdown = 12,
    InputDate = 13,
    InputTextField = 14,
    InputDropdownAndTextField = 15,
}

export type TAdvContractEvent = {
    DataType: EAdvContractEventType;
    GroupIndex: number; // the edit index
    StateKey: string; // an identifier to know which state triggered this event
};

export type TAdvContractEventCallback = {
    ContractName: string;
    GroupIndex: number;
    Args: {
        Name: string;
        Value: any;
    }[];
};

export type TAdvContractFieldDataValueChangedNtf = {
    ContractName: string;
    GroupIndex: number;
    Field: string;
    Prov: string;
    FieldName: string;
    Value: any;
};
