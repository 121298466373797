import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useMemo } from "react";

declare const config: {
    dataServerUrl: string;
    dataServerAlias?: string;
};

export function useSystemConfig() {
    const getDataServerUrl = useAdvCallback(function () {
        return new Promise<string>((resolve) => {
            resolve(config.dataServerUrl);
        });
    }, []);
    const dataServerAlias = useMemo(
        () =>
            typeof window == "undefined" || typeof config === "undefined"
                ? undefined
                : config.dataServerAlias,
        [],
    );

    return {
        getDataServerUrl,
        dataServerAlias,
    };
}
